@tailwind base;
@tailwind components;
@tailwind utilities;


*{
    overscroll-behavior: none !important;
}

/* Disables Image Dragging */
img{
  pointer-events: none;
}

/*

button {
  outline:0;
}
*/

/* Avoid Chrome to see Safari hack */
@supports (-webkit-touch-callout: none) {
  body {
    /* The hack for Safari */
    height: -webkit-fill-available;
  }
}

/* Google Map Customization */
.gm-style-cc, .gm-fullscreen-control, .gmnoprint{
  display: none !important;
}

.gm-style-iw-t{
  bottom: 55px !important;
}

.gm-style-iw button:focus {
    outline: 0;
}

  